"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAIN_SITE_NAME = exports.SITE_CONFIG_BY_SITE_NAME = void 0;
const utility_1 = require("./utility");
const createConfig = (config) => ({
    ...config,
    front: {
        ...config.front,
        template: {
            headTags: [
                `<title>${config.front.defaultTitle}</title>`,
                `<link href="https://fonts.googleapis.com/css2?${(0, utility_1.entries)(config.front.theme.googleFonts)
                    .map(([_name, font]) => font?.query)
                    .join('&')}&display=swap" rel="stylesheet">`,
            ].join(''),
        },
    },
});
exports.SITE_CONFIG_BY_SITE_NAME = {
    ['benkrejci.com']: createConfig({
        packageName: 'front-benkrejci.com',
        domainRoot: 'benkrejci.com',
        front: {
            domain: 'www.benkrejci.com',
            defaultTitle: 'Ben Krejci',
            theme: {
                googleFonts: {
                    primary: {
                        family: '"Roboto", sans-serif',
                        query: 'family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700',
                    },
                },
                mode: 'dark',
                primary: '#89beff',
                secondary: '#c9ce78',
                tertiary: '#e5a67f',
                quarternary: '#86ccb5',
                text: 'white',
                backgroundAngle: '325deg',
                backgroundPrimary: 'rgb(1, 6, 18)',
                backgroundBody: 'linear-gradient(335deg, rgb(32, 21, 42) 0%, rgb(1, 11, 34) 22%, rgb(1, 6, 18) 100%) fixed',
                backgroundPaper: 'rgba(28, 31, 49, 0.76)',
                shadowOpacity: 0.8,
            },
            // Copied from AWS console (Cloudwatch -> RUM)
            // (There's no way to get this info out of CDK as an output as of 2024-06-01)
            appMonitorConfig: {
                id: '010fefbe-d0c6-4e88-a38e-e2a162c37a13',
                version: '1.0.0',
                region: 'us-east-1',
                config: {
                    sessionSampleRate: 1,
                    identityPoolId: 'us-east-1:96e905cc-7d38-4f98-82ac-6e6c574b0b1c',
                    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                    telemetries: ['errors', 'performance', 'http'],
                    allowCookies: true,
                    enableXRay: false,
                },
            },
        },
        back: {
            domain: 'back.benkrejci.com',
            emailAddressFrom: 'bot@benkrejci.com',
            emailAddressTo: 'benkrejci@gmail.com',
        },
    }),
    ['caseygeorgi.com']: createConfig({
        packageName: 'front-caseygeorgi.com',
        domainRoot: 'caseygeorgi.com',
        front: {
            domain: 'www.caseygeorgi.com',
            defaultTitle: 'Casey Georgi',
            theme: {
                googleFonts: {
                    primary: {
                        family: '"Roboto", sans-serif',
                        query: 'family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700',
                    },
                    content: {
                        family: '"Lora", serif',
                        query: 'family=Lora:wght@400..700',
                    },
                },
                mode: 'light',
                primary: '#d56200',
                secondary: '#e12f91',
                text: 'black',
                backgroundPrimary: 'rgb(255, 252, 248)',
                backgroundBody: 'linear-gradient(155deg, rgb(255, 255, 255) 0%, rgb(249, 253, 242) 100%) fixed',
                backgroundPaper: 'rgb(255, 255, 255)',
                backgroundPaperSecondary: 'rgb(241, 237, 232)',
                shadowOpacity: 0.08,
            },
            appMonitorConfig: {
                id: 'e0035fe6-845b-4e6a-a65f-99447cc87f5a',
                version: '1.0.0',
                region: 'us-east-1',
                config: {
                    sessionSampleRate: 1,
                    identityPoolId: 'us-east-1:10769403-4d10-4566-8f05-2bdbe5f746ce',
                    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
                    telemetries: ['errors', 'performance', 'http'],
                    allowCookies: true,
                    enableXRay: false,
                },
            },
        },
        back: {
            domain: 'back.caseygeorgi.com',
            emailAddressFrom: 'caseybot@caseygeorgi.com',
            emailAddressTo: 'the.curious.georgi@gmail.com',
        },
    }),
    ['theogriffin.com']: createConfig({
        packageName: 'front-theogriffin.com',
        domainRoot: 'theogriffin.com',
        front: {
            domain: 'www.theogriffin.com',
            defaultTitle: 'Theo Griffin',
            theme: {
                googleFonts: {
                    primary: {
                        family: '"Roboto", sans-serif',
                        query: 'family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700',
                    },
                },
                mode: 'light',
                primary: '#7353d5',
                secondary: '#0081d5',
                text: 'black',
                backgroundPrimary: '#ffffff',
                backgroundBody: 'linear-gradient(155deg, rgb(255, 255, 255) 0%, rgb(242 247 253) 100%) fixed',
                backgroundPaper: '#ffffff',
                backgroundPaperSecondary: '#f7f7fa',
                shadowOpacity: 0.08,
            },
        },
        back: {
            domain: 'back.theogriffin.com',
            emailAddressFrom: 'theobot@benkrejci.com',
            emailAddressTo: 'theogriffin1@gmail.com ',
        },
    }),
};
exports.MAIN_SITE_NAME = 'benkrejci.com';
