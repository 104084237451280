import { ApiGallery } from '@benkrejci/shared/src/api/api'
import { getDate } from '@benkrejci/shared/src/api/utility'
import { Route, Routes, useParams } from 'react-router-dom'
import { MediaModal } from '../MediaModal'
import { GalleryItem } from './GalleryChild'
import { GalleryGrid } from './GalleryGrid'
import { GalleryMasonry } from './GalleryMasonry'
import { staticAssertNever } from '@benkrejci/shared/src/utilityTypes'

export const Gallery = ({ widget }: { widget: ApiGallery }) => {
  return (
    <Routes>
      <Route path="/:openImageHash?" element={<GalleryContent widget={widget} />} />
    </Routes>
  )
}

const FILENAME_DATE_REGEX = /(?:^|[^0-9])(\d{4})-?(\d{2})-?(\d{2})(?:$|[^0-9])/

const getMediaEpochMs = (media: NonNullable<ApiGallery['media']>[number]) => {
  const date = (() => {
    const dateMatch = FILENAME_DATE_REGEX.exec(media.name ?? '')
    if (dateMatch !== null) {
      return new Date(`${dateMatch[1]}-${dateMatch[2]}-${dateMatch[3]}`)
    }
    if (media.createdAt !== undefined) {
      return getDate(media.createdAt)
    }
    throw new Error('Could not find a date for media')
  })()

  return date.getTime()
}

const GalleryContent = ({ widget }: { widget: ApiGallery }) => {
  // TODO: Sort by other things
  const items: GalleryItem[] =
    widget.media
      ?.sort((a, b) => getMediaEpochMs(b) - getMediaEpochMs(a))
      .map((media) => ({ ...media, viewUrl: `../${media.hash}` })) ?? []

  const openImageHash = useParams()?.openImageHash
  const currentModalMedia = widget.media?.find((media) => media.hash === openImageHash)
  const getNavigateMediaHref = (isForward: boolean) => {
    if (openImageHash === undefined || items === undefined) return
    const currentIndex = items.findIndex((media) => media.hash === openImageHash)
    if (currentIndex === undefined || currentIndex === -1) return
    const newIndex = (currentIndex + (isForward ? 1 : -1) + items.length) % items.length
    return `../${items[newIndex].hash}`
  }

  return (
    <>
      <MediaModal
        media={currentModalMedia}
        closeHref=".."
        nextHref={getNavigateMediaHref(true)!}
        previousHref={getNavigateMediaHref(false)!}
      />
      {(() => {
        switch (widget.displayType) {
          case 'masonry':
            return <GalleryMasonry widget={widget} items={items} />
          case 'carousel':
            throw Error('Carousel not implemented')
          case 'grid':
            return <GalleryGrid widget={widget} items={items} />
          default:
            staticAssertNever(widget.displayType)
        }
      })()}
    </>
  )
}
