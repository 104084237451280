import Markdown, { MarkdownToJSX } from 'markdown-to-jsx'
import React from 'react'
import { css } from '@emotion/react'

import { Box, Grid, Paper, Typography } from '@mui/material'

import { Media } from '../Media'
import { Link } from '../../utility/Link'
import { ApiRichText } from '@benkrejci/shared/src/api/api'
import { getFormatCss } from '../../style/theme'
import { getResponsiveSpace } from '../../style/global'
import * as globalStyles from '../../style/global'
import { Tag } from '@mui/icons-material'
import { MediaModal } from './MediaModal'

const HEADING_LEVELS = [1, 2, 3, 4, 5] as const
const BLOCK_SPACE = 3
const MD_HEADING_MATCH = /^#+\s*.+$/m

export function RichText({
  content,
  children,
  format,
  topHeadingLevel = 2,
  topHeadingVariantLevel = 2,
  className,
  useHeadingLinks = false,
  enableMediaModal = true,
}: {
  content?: string
  children?: string
  format?: ApiRichText['format']
  topHeadingLevel?: number
  topHeadingVariantLevel?: number
  className?: string
  useHeadingLinks?: boolean
  enableMediaModal?: boolean
}) {
  const [modalSrc, setModalSrc] = React.useState<string>()
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  const onModalChange = (isForward: boolean) => {
    if (wrapperRef.current === null) return
    const mediaTags = Array.from(
      wrapperRef.current.getElementsByClassName('rich-text-media'),
    )
    const currentIndex = mediaTags.findIndex(
      (tag) => tag.getAttribute('data-media-src') === modalSrc,
    )
    const nextIndex =
      (currentIndex + (isForward ? 1 : mediaTags.length - 1)) % mediaTags.length
    const nextSrc = mediaTags[nextIndex]?.getAttribute('data-media-src')
    if (nextSrc !== null) {
      setModalSrc(nextSrc)
    }
  }
  const renderImage = ({
    src,
    ...props
  }: { src: string } & React.ComponentProps<'img'>) => {
    if (enableMediaModal) {
      return (
        <>
          <span
            className="rich-text-media"
            data-media-src={src}
            css={css`
              display: none;
            `}
          />
          <Media
            src={src}
            {...props}
            render={({ children }) => (
              <Link
                href={src}
                onClick={(e) => {
                  if (e.ctrlKey || e.metaKey || e.shiftKey) return
                  // Open the modal instead of going to the link
                  // The link is just there so you can open the image in a new tab
                  e.preventDefault()
                  setModalSrc(src)
                }}
              >
                {children}
              </Link>
            )}
          />
        </>
      )
    }
    return (
      <Link href={src}>
        <Media src={src} {...props} />
      </Link>
    )
  }
  const options = {
    ...DEFAULT_OPTIONS(renderImage),
  } satisfies MarkdownToJSX.Options

  // start the heading levels at top and work down to h5
  HEADING_LEVELS.forEach((inLevel) => {
    const outLevel = inLevel - 1 + topHeadingLevel
    const outVariantLevel = inLevel - 1 + topHeadingVariantLevel
    options.overrides[`h${inLevel}`].props.component = `h${outLevel}`
    options.overrides[`h${inLevel}`].props.variant = `h${outVariantLevel}`

    if (useHeadingLinks) {
      // @ts-expect-error
      options.overrides[`h${inLevel}`].component = ({ children, id, ...props }) => (
        <Typography {...props} id={id}>
          <Link href={`#${id}`} css={globalStyles.headingLinkWithTag}>
            <Tag
              fontSize="small"
              titleAccess="opens in new window"
              css={globalStyles.headingTagIcon}
            />
            {children}
          </Link>
        </Typography>
      )
    }
  })

  const hasHeadingLinks =
    useHeadingLinks && MD_HEADING_MATCH.test(children ?? content ?? '')

  return (
    <>
      <MediaModal
        media={modalSrc}
        onClose={() => setModalSrc(undefined)}
        onNext={() => onModalChange(true)}
        onPrevious={() => onModalChange(false)}
      />
      <Box
        typography="body2"
        className={className}
        css={(theme) => css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(BLOCK_SPACE)};
          // Make extra room for the heading tags
          padding-left: ${hasHeadingLinks ? '0.5rem' : '0'};
          // Remove positive margin because it will stack with gap
          h1,
          h2 {
            margin-bottom: 0;
          }
          // These headings should be a little more snug with their content than the gap alone
          h3,
          h4,
          h5 {
            margin-bottom: -0.5rem;
          }

          ${getFormatCss(format, theme)}
        `}
        ref={wrapperRef}
      >
        <Markdown options={options}>{children ?? content ?? ''}</Markdown>
      </Box>
    </>
  )
}

const DEFAULT_OPTIONS = (
  renderMedia: (props: { src: string } & React.ComponentProps<'img'>) => React.ReactNode,
) => {
  return {
    wrapper: ({ children }) => children,
    // renderRule: (next, node, renderChildren, state) => {
    //   if (node.type === RuleType.htmlBlock)
    //   return next()
    // },
    overrides: {
      h1: {
        component: Typography,
        props: {
          component: 'h2',
          variant: 'h2',
        },
      },
      h2: {
        component: Typography,
        props: {
          component: 'h3',
          variant: 'h3',
        },
      },
      h3: { component: Typography, props: { component: 'h4', variant: 'h4' } },
      h4: {
        component: Typography,
        props: { component: 'h5', variant: 'h5' },
      },
      h5: {
        component: Typography,
        props: { component: 'h6', variant: 'h6' },
      },
      p: {
        component: (props) => (
          <Typography
            {...props}
            css={(theme) => css`
              display: flex;
              gap: ${theme.spacing(BLOCK_SPACE)};
              margin-bottom: 0;
              flex-wrap: wrap;
            `}
          />
        ),
        props: { component: 'div', variant: 'body2' },
      },
      a: {
        component: (props) => (
          <Link
            {...props}
            css={css`
              display: contents;
            `}
          />
        ),
      },
      span: {
        component: (props) => (
          <span
            {...props}
            css={css`
              display: contents;
            `}
          />
        ),
      },
      em: {
        component: (props) => (
          <em
            {...props}
            css={css`
              display: contents;
            `}
          />
        ),
      },
      b: {
        component: (props) => (
          <b
            {...props}
            css={css`
              display: contents;
            `}
          />
        ),
      },
      img: renderMedia,
      Media: renderMedia,
      ul: {
        component: ({ children, ...props }) => (
          <ul
            {...props}
            css={css`
              margin: 0;
            `}
          >
            {children}
          </ul>
        ),
      },
      li: {
        component: (props) => (
          <li>
            <Typography component="span" {...props} />
          </li>
        ),
      },
      Box: (props) => <Box {...parseStringProps(props)} />,
      Paper: Paper,
      Grid: ({ container, ...props }) => (
        <Grid
          {...(container ? { container, spacing: getResponsiveSpace() } : {})}
          {...parseStringProps(props)}
        />
      ),
    },
  } satisfies MarkdownToJSX.Options
}

// Even though markdown-to-jsx allows attr={value} syntax, it still only passes strings
// parseStringProps converts known numbers so that they can be used correctly by MUI components
const parseStringProps = (props: Record<string, string>) => {
  const parsed: Record<string, string | number> = {}
  for (const key in props) {
    parsed[key] = ['xs', 'sm', 'md', 'lg', 'xl'].includes(key)
      ? parseInt(props[key])
      : props[key]
  }
  return parsed
}
