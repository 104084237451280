import { useParams } from 'react-router-dom'
import { ApiPage, getPost } from '@benkrejci/shared/dist/api/api'
import { Page } from '../Page'
import { Alert } from '@mui/material'
import { Post } from '../widgets/Post'
import { DataLoader } from '../DataLoader'
import { useCachedFetch } from '../../utility/api/useCachedFetch'
import { parseIdOrSlug } from '@benkrejci/shared/dist/api/route'
import { useSite } from '../../utility/api/useSite'

export const PostPageWithData = () => {
  const { idOrSlug } = useParams()
  if (idOrSlug === undefined) throw Error('idOrSlug is undefined')
  const params = parseIdOrSlug(idOrSlug)

  const siteState = useSite()

  const postState = useCachedFetch<
    Awaited<ReturnType<typeof getPost>>,
    { id: number; slug?: string }
  >({
    typeId: 'post',
    params,
    load: ({ id, slug }) => getPost(id ?? slug!),
    getParams: ({ id, slug }) => ({ id, slug }),
    getErrorMessage: () => 'Oh no! There was a problem loading that post 😭',
    doLoad: idOrSlug !== undefined,
  })

  if (idOrSlug === undefined) {
    return <Alert severity="error">Nothing to see here...</Alert>
  }

  return (
    <DataLoader fetchState={[siteState, postState]} showSpinner={false}>
      {([site, post], isLoading) => {
        const page: ApiPage = {
          slug: post?.slug ?? params.slug ?? '',
          url: undefined,
          file: undefined,
          title: post?.title,
          description: post?.description,
          fullBleed: false,
          hideSocial: false,
          hideSocialDown: undefined,
          hideSocialUp: undefined,
          widgetsMain: [],
          widgetsLeft: [],
          widgetsRight: [],
        }
        // If this is a post, find a page with a post grid or post list widget
        // that includes this post
        const idNum = post?.id
        const { slug: categoryPageSlug, title: categoryPageLabel } =
          site?.navTopPages?.find((p) =>
            p.widgetsMain?.find(
              (w) =>
                (w.__component === 'widget.post-grid' ||
                  w.__component === 'widget.post-list') &&
                (w.allPosts || w.posts?.find((po) => po.id === idNum)),
            ),
          ) ?? {}
        return (
          <Page
            isLoading={isLoading}
            slug={categoryPageSlug ?? ''}
            backButton={{ label: categoryPageLabel, href: `/${categoryPageSlug}` }}
            site={site}
            page={page}
          >
            <Post isLoading={isLoading} post={post} isPage={true} />
          </Page>
        )
      }}
    </DataLoader>
  )
}
