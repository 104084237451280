import React from 'react'

import { css } from '@emotion/react'
import { alpha, Grid, Paper, Typography } from '@mui/material'

import { ApiPostGrid } from '@benkrejci/shared/dist/api/api'
import { usePosts } from './usePosts'
import { DataLoader } from '../DataLoader'
import { Media } from '../Media'
import { getPostUrl } from '@benkrejci/shared/dist/api/route'
import { Link } from '../../utility/Link'
import { getGridPropsFromSize, getResponsiveSpace } from '../../style/global'
import { LoadingBox } from '../../utility/LoadingBox'

export const PostGrid = ({ widget }: { widget: ApiPostGrid }) => {
  const postsFetchState = usePosts(widget.allPosts)

  if (widget.allPosts) {
    return (
      <DataLoader fetchState={postsFetchState} showSpinner={false}>
        {(posts) => (
          <PostGridInner
            isLoading={postsFetchState.isLoading}
            widget={widget}
            posts={posts}
          />
        )}
      </DataLoader>
    )
  }
  return <PostGridInner isLoading={false} widget={widget} posts={widget.posts ?? []} />
}

const PostGridInner = ({
  isLoading,
  widget,
  posts,
}: {
  isLoading: boolean
  widget: ApiPostGrid
  posts: ApiPostGrid['posts']
}) => (
  <Grid container spacing={getResponsiveSpace()}>
    {isLoading
      ? new Array(5).fill(0).map((_zero, index) => (
          <Grid
            item
            key={index}
            {...getGridPropsFromSize(widget.imageSize ?? 'medium')}
            css={css`
              aspect-ratio: 1;
            `}
          >
            <LoadingBox isLoading />
          </Grid>
        ))
      : posts?.map((post) => (
          <Grid
            item
            key={post.id}
            {...getGridPropsFromSize(widget.imageSize ?? 'medium')}
          >
            <Link
              href={getPostUrl(post)}
              css={css`
                display: block;
                height: 100%;
              `}
            >
              <Paper
                css={css`
                  position: relative;
                  height: 100%;
                  aspect-ratio: 1;
                `}
                elevation={
                  widget.imageSize === 'small' ? 1 : widget.imageSize === 'medium' ? 4 : 8
                }
              >
                {post.cover == null ? null : (
                  <Media
                    file={post.cover}
                    autoPlay={true}
                    controls={false}
                    muted={true}
                    height="100%"
                    width="100%"
                    maxHeight="100%"
                    imageFit={widget.coverFit}
                  />
                )}
                {widget.showTitles ? (
                  <Typography
                    variant="h4"
                    css={(theme) => css`
                      position: absolute;
                      /* font-size: 2rem !important; */
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      &:hover {
                        background-color: ${alpha(theme.palette.background.default, 0.5)};
                      }
                      /* text-shadow: ${[12, 11, 10, 9, 8, 7, 6, 5, 5, 5]
                        .map(
                          (size) =>
                            `0 0 ${theme.spacing(size)} ${alpha(theme.palette.getContrastText(theme.palette.text.primary), 1)}`,
                        )
                        .join(',')}; */
                    `}
                  >
                    <div
                      css={(theme) => css`
                        width: 100%;
                        padding: ${theme.spacing(5)} ${theme.spacing(2)};
                        /* background-color: ${alpha(
                          theme.palette.background.default,
                          0.5,
                        )}; */
                        background: radial-gradient(
                          closest-side,
                          ${alpha(theme.palette.background.default, 0.6)} 55%,
                          transparent
                        );
                      `}
                    >
                      {post.title}
                    </div>
                  </Typography>
                ) : null}
              </Paper>
            </Link>
          </Grid>
        ))}
  </Grid>
)
